import React from 'react'
import { Card, Spinner } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getDisplayPrice, useCQuery } from '../../Services'

export const Affiliazioni = ({ range }) => {
  const { isSuccess, data: statAff } = useCQuery(['statistiche', 'affiliazioni', range])
  const { isSuccess: isSuccessGalgo, data: statGalgo } = useCQuery(['statistiche', 'galgo', range])

  const templateEur = ({ Tot }) => getDisplayPrice(Tot)
  console.log(isSuccessGalgo, statGalgo)
  return !isSuccess || !isSuccessGalgo ? <Spinner /> : (
    <>
      <Card style={{ marginTop: 20, width: '100%' }}>
        <DataTable
          value={statAff} paginator rows={20} emptyMessage='Nessun Prodotto Trovato'
        >
          <Column field='Nome' header='Affiliazione' />
          <Column field='Cnt' header='Numero Vendite' style={{ width: 260 }} />
          <Column field='Tot' header='Totale Acquisti' style={{ width: 260 }} body={templateEur} />
        </DataTable>
      </Card>

      <Card style={{ marginTop: 20, width: '100%' }}>
        <DataTable
          value={statGalgo} paginator rows={20} emptyMessage='Nessun Prodotto Trovato'
        >
          <Column field='Tipo' header='Vendite a Galgo tracciamento negozio' />
          <Column field='Totale' header='Totale Acquisti' style={{ width: 260 }} body={templateEur} />
        </DataTable>
      </Card>
    </>
  )
}
